<template>
  <div class="hold-page">
    <b-card>
      <h3 class="mb-2">Farming</h3>
      <div class="mb-3">
        <b-row>
          <b-col cols="12" md="4">
            <div class="hold-page__title">Used free accounts</div>
          </b-col>
          <b-col cols="12" md="5">
            <div class="hold-page__desc">{{ freeAccounts.freeUsers.number }}</div>
          </b-col>
        </b-row>
      </div>
      <div v-for="(item, index) in info" :key="index" class="mb-2">
        <validation-provider #default="{ errors }" :name="item.title" rules="required|min_value:0">
          <b-row>
            <b-col cols="12" md="4">
              <div class="hold-page__title">{{ item.title }}</div>
            </b-col>
            <b-col v-if="!item.isEdit" cols="12" md="5">
              <div class="hold-page__desc">{{ form[item.name] }}</div>
            </b-col>
            <b-col v-if="!item.isEdit" cols="12" md="3">
              <span class="d-flex">
                <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="edit(index)">
                  <span class="text-white"> Edit </span>
                </b-button>
              </span>
            </b-col>
            <b-col v-if="item.isEdit" cols="12" md="4">
              <b-form-group :label-for="item.name">
                <b-form-input v-if="!item.isText" type="number" :id="item.name" v-model.number="inputForm[item.name]" :state="errors.length ? false : null" />
                <b-form-input v-else type="text" :id="item.name" v-model.trim="inputForm[item.name]" :state="errors.length ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </b-col>
            <b-col v-if="item.isEdit" cols="12" md="3">
              <span class="d-flex">
                <b-button class="mt-auto text-white ml-1" :disabled="!!errors[0]" type="button" variant="primary" @click.prevent="save(item.name, index)">
                  <span class="text-white"> Save </span>
                </b-button>
                <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="cancel(item.name, index)">
                  <span class="text-white"> Cancel </span>
                </b-button>
              </span>
            </b-col>
          </b-row>
        </validation-provider>
      </div>
    </b-card>

    <b-card>
      <h3 class="mb-2">Reward percentages</h3>
      <div v-for="(item, index) in holdInfo['farming.referral-reward-percentages']" :key="index" class="mb-2">
        <validation-provider #default="{ errors }" :name="`level ${index + 1}`" rules="required|min_value:0">
          <b-row>
            <b-col cols="12" md="4">
              <div class="hold-page__title">Level {{ index + 1 }}</div>
            </b-col>
            <b-col v-if="!isEditLevel" cols="12" md="5">
              <div class="hold-page__desc">
                {{ levelInput[`level${index+1}`] }}
              </div>
            </b-col>
            <b-col v-if="!isEditLevel" cols="12" md="3">
              <span class="d-flex">
                <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="isEditLevel = true">
                  <span class="text-white"> Edit </span>
                </b-button>
              </span>
            </b-col>
            <b-col v-if="isEditLevel" cols="12" md="4">
              <b-form-group :label-for="`referral-reward-percentages${index}`">
                <b-form-input
                  v-model.trim="levelInput[`level${index+1}`]"
                  :id="`referral-reward-percentages${index}`"
                  type="text"
                  :state="errors.length ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </b-col>
            <b-col v-if="isEditLevel" cols="12" md="3">
              <span class="d-flex">
                <b-button class="mt-auto text-white ml-1" :disabled="!!errors[0]" type="button" variant="primary" @click.prevent="save('farming.referral-reward-percentages', index)">
                  <span class="text-white"> Save </span>
                </b-button>
                <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="cancel('farming.referral-reward-percentages', index)">
                  <span class="text-white"> Cancel </span>
                </b-button>
              </span>
            </b-col>
          </b-row>
        </validation-provider>
      </div>
    </b-card>

    <b-card>
      <h3 class="mb-2">Referral Levels</h3>
      <AppTable
        :rows="levels"
        :columns="columns"
        :is-loading="requestInProgress"
        :is-pagination="false"
      >
        <template #default="{ column, row, formattedRow }">
          <!-- Column: Action -->
          <span v-if="column.field === 'action'">
            <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="editLevel(row.id)">
              <span class="text-white"> Edit </span>
            </b-button>
          </span>
          <!-- default render cell -->
          <span v-else class="flex-center-align">
            {{ formattedRow[column.field] }}
          </span>
        </template>
      </AppTable>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BCard } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import Ripple from 'vue-ripple-directive'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'HoldSettings',
  components: {
    ToastificationContent,
    ValidationProvider,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    AppTable,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
      form: {},
      inputForm: {},
      isEditLevel: false,
      levelInput: {
        level1: null,
        level2: null,
      },
      info: [
        {
          title: "Free users limit",
          name: "referrals.free-users.limit",
          isEdit: false,
        },
        {
          title: "Farming time",
          name: "farming.settings.time",
          isEdit: false,
        },
        {
          title: "Default number of available links",
          name: "referrals.activations.default-total",
          isEdit: false,
        },
        {
          title: "Successful invite reward",
          name: "referrals.activations.reward",
          isEdit: false,
        },
        {
          title: "Number of links to buy",
          name: "referrals.activations.quantity",
          isEdit: false,
        },
        {
          title: "Price for 10 links to buy",
          name: "referrals.activations.price",
          isEdit: false,
        },
        {
          title: "Amount of farmed HOLD per second",
          name: "farming.settings.amount",
          isEdit: false,
          isText: true,
        },
        // {
        //   title: "Interval",
        //   name: "farming.settings.interval",
        //   isEdit: false,
        // },
      ],
    }
  },

  computed: {
    ...mapGetters({
      holdInfo: 'hold/holdInfo',
      freeAccounts: 'hold/freeAccounts',
      levels: 'hold/levels',
    }),
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchHoldInfo: 'hold/fetchHoldInfo',
      updateHoldInfo: 'hold/updateHoldInfo',
      getFreeAccounts: 'hold/getFreeAccounts',
      fetchHoldLevels: 'hold/fetchHoldLevels',
    }),
    async initState() {
      await this.fetchHoldInfo().catch(this.checkErrors)
      await this.getFreeAccounts().catch(this.checkErrors)
      await this.fetchHoldLevels().catch(this.checkErrors)
      this.form = this.holdInfo
      this.inputForm = JSON.parse(JSON.stringify(this.holdInfo))
      this.form['farming.referral-reward-percentages'].forEach((item, index) => {
        this.levelInput[`level${index + 1}`] = item
      })
    },
    edit(index) {
      this.info[index].isEdit = true
    },
    cancel(name, index) {
      if (name === 'farming.referral-reward-percentages') {
        this.form['farming.referral-reward-percentages'].forEach((item, index) => {
          this.levelInput[`level${index + 1}`] = item
        })
        this.isEditLevel = false
        return
      }
      this.inputForm[name] = this.form[name]
      this.info[index].isEdit = false
    },
    editLevel(id) {
      console.log('editLevel', id)
      this.$router.push(`/hold-settings/level-edit/${id}`)
    },
    async save(name, index) {
      if (name === 'farming.referral-reward-percentages') {
        this.isEditLevel = false
        const form = {}
        form['farming.referral-reward-percentages'] = Object.values(this.levelInput)
        console.log(form)
        this.update(form)
        return
      }
      this.form[name] = this.inputForm[name]
      this.info[index].isEdit = false
      const form = {}
      form[name] = this.form[name]
      this.update(form)
    },

    update(form) {
      this.waitRequest(() => {
        return this.updateHoldInfo(form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated',
                icon: 'PlusCircleIcon',
                variant: 'info',
              },
            })
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
