export default [
  // {
  //   label: 'ID',
  //   field: 'id',
  // },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Referrals',
    field: 'referrals',
  },
  {
    label: 'Reward Multiplier',
    field: 'rewardMultiplier',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
